<div [attr.id]="supportSection.id" class="relative py-3xl">
  <div
    class="absolute bottom-0 left-0 right-0 top-0"
    style="background: linear-gradient(266.7deg, var(--purple-300) 0%, var(--purple-800) 100%)"
  ></div>

  <div class="h-content-layout grid items-center md:grid-cols-2 md:gap-4xl">
    <div class="z-10 text-white">
      <cft-content-section
        class="text-white"
        [plot]="supportSection.plot"
        [title]="supportSection.title"
        [description]="supportSection.description"
        [titleAlignment]="'left'"
        [suppressHTag]="true"
      >
        <div content="actions w-full md:w-auto" class="space-y-sm">
          @if (supportSection.phoneNumber) {
            <a
              class="btn btn-elevated btn-secondary w-full md:mr-md md:w-auto"
              [href]="supportSection.phoneNumber | bgoTel"
            >
              <fa-icon [icon]="phoneIcon" class="pr-sm"></fa-icon>
              {{ supportSection.phoneNumber }}
            </a>
          }
          <a class="btn btn-light-outlined w-full md:w-auto" [href]="supportSection.email | bgoMailTo">
            <fa-icon [icon]="emailIcon" class="pr-sm"></fa-icon>
            {{ supportSection.email }}
          </a>
        </div>
      </cft-content-section>
    </div>
    <div class="z-10 mt-2xl md:mt-0">
      <cft-support-box
        [title]="supportSection.contactTitle"
        [icon]="undefined"
        [description]="supportSection.contactDescription"
        [contact]="{
          name: supportSection.contactName,
          role: supportSection.contactRole ?? '',
          email: supportSection.email,
          phone: supportSection.phoneNumber
        }"
      >
        <img
          [ngSrc]="supportSection.image.url"
          [alt]="supportSection.image.title"
          class="h-10 w-10 rounded-full border-2 border-purple-500 object-cover"
          style="object-position: center 25%"
          content="portrait"
          width="80"
          height="80"
        />
      </cft-support-box>
    </div>
  </div>
</div>
