import {Component, Input} from '@angular/core'
import {faPhoneAlt} from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import {faEnvelope} from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import {SupportSection} from '../../../../core/content/types/support-section.types'
import {PipesModule} from '../../../pipes/pipes.module'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'
import {SupportBoxModule} from '../../../../craft/support/support-box/support-box.module'
import {NgOptimizedImage} from '@angular/common'
import {ContentSectionComponent} from '../../../../craft/content-section/content-section.component'

@Component({
  selector: 'bgo-support-section',
  templateUrl: './support-section.component.html',
  styles: [':host { display: block; }'],
  imports: [ContentSectionComponent, PipesModule, FaIconComponent, SupportBoxModule, NgOptimizedImage],
  standalone: true,
})
export class SupportSectionComponent {
  @Input() supportSection!: SupportSection
  readonly phoneIcon = faPhoneAlt
  readonly emailIcon = faEnvelope
}
